/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../graphql/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type IGetReportCategoriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IGetReportCategoriesQuery = {
    __typename?: 'Query';
    getAllReportsQuickFilters: {
        __typename?: 'AllReportsQuickFilterPage';
        filters: Array<{
            __typename?: 'AllReportsQuickFilter';
            filter?: Types.Maybe<{ __typename?: 'FilterPayloadValue'; label?: Types.Maybe<string>; value: string }>;
        }>;
    };
};

export const GetReportCategoriesDocument = gql`
    query getReportCategories {
        getAllReportsQuickFilters {
            filters {
                filter {
                    label
                    value
                }
            }
        }
    }
`;

/**
 * __useGetReportCategoriesQuery__
 *
 * To run a query within a React component, call `useGetReportCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReportCategoriesQuery(
    baseOptions?: Apollo.QueryHookOptions<IGetReportCategoriesQuery, IGetReportCategoriesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<IGetReportCategoriesQuery, IGetReportCategoriesQueryVariables>(
        GetReportCategoriesDocument,
        options,
    );
}
export function useGetReportCategoriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IGetReportCategoriesQuery, IGetReportCategoriesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IGetReportCategoriesQuery, IGetReportCategoriesQueryVariables>(
        GetReportCategoriesDocument,
        options,
    );
}
export type GetReportCategoriesQueryHookResult = ReturnType<typeof useGetReportCategoriesQuery>;
export type GetReportCategoriesLazyQueryHookResult = ReturnType<typeof useGetReportCategoriesLazyQuery>;
export type GetReportCategoriesQueryResult = Apollo.QueryResult<
    IGetReportCategoriesQuery,
    IGetReportCategoriesQueryVariables
>;
