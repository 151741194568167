/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../graphql/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type ISetUiThemeMutationVariables = Types.Exact<{
    userId: Types.Scalars['String'];
    theme: Types.IUiTheme;
}>;

export type ISetUiThemeMutation = {
    __typename?: 'Mutation';
    setUITheme?: Types.Maybe<{ __typename?: 'UserPreferences'; theme?: Types.Maybe<Types.IUiTheme> }>;
};

export const SetUiThemeDocument = gql`
    mutation setUiTheme($userId: String!, $theme: UITheme!) {
        setUITheme(userId: $userId, theme: $theme) {
            theme
        }
    }
`;
export type ISetUiThemeMutationFn = Apollo.MutationFunction<ISetUiThemeMutation, ISetUiThemeMutationVariables>;

/**
 * __useSetUiThemeMutation__
 *
 * To run a mutation, you first call `useSetUiThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUiThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUiThemeMutation, { data, loading, error }] = useSetUiThemeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useSetUiThemeMutation(
    baseOptions?: Apollo.MutationHookOptions<ISetUiThemeMutation, ISetUiThemeMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ISetUiThemeMutation, ISetUiThemeMutationVariables>(SetUiThemeDocument, options);
}
export type SetUiThemeMutationHookResult = ReturnType<typeof useSetUiThemeMutation>;
export type SetUiThemeMutationResult = Apollo.MutationResult<ISetUiThemeMutation>;
export type SetUiThemeMutationOptions = Apollo.BaseMutationOptions<ISetUiThemeMutation, ISetUiThemeMutationVariables>;
