import React from 'react';
import { useAuth } from '@acin/user-accounts';
import { Router } from './routes';

const AUTO_LOGOUT_TIME = 30 * 60 * 1000; // 30 minutes
// const AUTO_LOGOUT_TIME = 10000; // 10 seconds for testing purposes

export const App: React.FC = () => {
    const { logout } = useAuth();
    const timer = React.useRef<number | undefined>(undefined);

    const handleLogout = React.useCallback(() => {
        localStorage.clear();
        let redirect: any;
        if (process.env.NODE_ENV !== 'development') {
            redirect = {
                returnTo: `https://${window.location.hostname}`,
            };
        }
        if (process.env.REACT_APP_CYPRESS_ENABLE === 'true') {
            redirect = {
                returnTo: `http://${window.location.hostname}:3000`,
            };
        }

        logout(redirect);
    }, [logout]);

    const resetTimer = React.useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = window.setTimeout(handleLogout, AUTO_LOGOUT_TIME);
    }, [handleLogout]);

    React.useEffect(() => {
        const activityEvents: (keyof WindowEventMap)[] = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
        const handleUserActivity = () => {
            activityEvents.forEach((event) => window.addEventListener(event, resetTimer));
        };

        handleUserActivity();
        resetTimer();

        return () => {
            activityEvents.forEach((event) => window.removeEventListener(event, resetTimer));
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, [resetTimer]);

    return <Router />;
};

export default App;
