import styled, { css, keyframes } from 'styled-components';
import { IconDownload, View, themeHelpers } from '@acin/ui-core';

const { zIndex } = themeHelpers;

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(0.6); }
  100% { transform: scale(1);}
`;

export const DownloadIconAnimated = styled(IconDownload)<{ dotColor?: string; stopAnimation: boolean }>`
    position: relative;
    overflow: visible;

    ${(p) => {
        if (p.dotColor) {
            const dot = css`
                display: block;
                width: 10px;
                height: 10px;
                content: '';
                background: ${p.dotColor};
                position: absolute;
                border-radius: 50%;
                left: 14px;
                top: 0px;
            `;

            return p.stopAnimation
                ? css`
                      &::after {
                          ${dot}
                      }
                  `
                : css`
                      &::after {
                          ${dot};
                          animation-name: ${pulseAnimation};
                          animation-duration: 2s;
                          animation-iteration-count: infinite;
                      }
                  `;
        }
    }};
`;

export const DownloadsWrapper = styled(View)<{ topPosition?: number }>`
    position: fixed;
    left: auto;
    right: 90px;
    top: ${(p) => (p.topPosition ? `${p.topPosition + 60}px` : `60px`)};
    z-index: calc(${zIndex} + 10);
`;
