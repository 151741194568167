import { UserMetadata, AuthUser } from '@acin/user-accounts';
import { trackPendoEvent } from '@acin/ui-core';
import { clearSavedFilters } from '@acin/filters';
import { datadogRum } from '@datadog/browser-rum';
import { initialisePendo } from '../helpers/pendo';

export const onAuthenticated = (user: AuthUser, userMetadata: UserMetadata) => {
    initialisePendo(user, userMetadata);
    trackPendoEvent('Screen Size', {
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
    });
    datadogRum.setUser({
        id: user.sub,
        email: user.email,
        name: user.name,
    });
};

export const onLogout = () => {
    clearSavedFilters();
};
