import styled, { css } from 'styled-components';
import { Text, View } from '@acin/ui-core';

export const Thumbnail = styled.button`
    border: none;
    background: transparent;
    text-align: left;
    cursor: pointer;
    padding: 0;

    &:not(:first-child) {
        margin-left: ${(p) => p.theme.spacing.getSize(8)};
    }
`;

export const SVGWrapper = styled(View)<{ isActive?: boolean }>`
    box-sizing: border-box;
    border-radius: ${(p) => p.theme.spacing.getSize()};
    display: inline-flex;
    border: 2px solid transparent;
    overflow: hidden;

    ${(p) =>
        p.isActive &&
        css`
            border-color: ${(p) => p.theme.colors.primary.getValue(500).main};
        `}
`;

export const Title = styled(Text)`
    margin-top: ${(p) => p.theme.spacing.getSize(2)};
    color: ${(p) => p.theme.colors.text.getValue(1)};
`;
