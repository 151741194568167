import { snakeCase } from 'lodash';

export interface AnalyticsPayload {
    [key: string]: string;
}

class Analytics {
    private urlMap: Map<string, object>;

    constructor() {
        this.urlMap = new Map();
    }

    setUrl(route?: string, title?: string, params?: object) {
        let comps: string[] = [];

        if (route) {
            comps = [...comps, route];
        }

        if (title) {
            comps = [...comps, snakeCase(title)];
        }

        this.urlMap.set(window.location.pathname, this.getObject(comps.join('/'), params));
    }

    getObject(page: string, params = {}): object {
        return { ...params, page };
    }

    getAnalyticsUrl() {
        return this.urlMap.get(window.location.pathname) || {};
    }
}

export const analytics = new Analytics();
