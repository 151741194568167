import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { History } from 'history';

interface ScrollToTopProps {
    history: History;
}

const ScrollToTop: React.FC<ScrollToTopProps> = () => {
    const history = useHistory();

    React.useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, [history]);

    return null;
};

export default withRouter(ScrollToTop);
