import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { PrivateLayout } from './PrivateLayout';

import { Login } from './Login';
import { Auth } from './Auth';

export const Router = () => (
    <Switch>
        <Route path="/login">
            <Login />
        </Route>
        {/*
            This is the auth callback url - it will redirect to the dashboard
        */}
        <Route path="/auth">
            <Auth />
        </Route>
        <PrivateRoute path="/">
            {/* All private routing is done inside the Private layout */}
            <PrivateLayout />
        </PrivateRoute>
    </Switch>
);
