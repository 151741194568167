/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../graphql/generated';

import { gql } from '@apollo/client';
export type IUserFragment = {
    __typename?: 'User';
    id: string;
    name: string;
    avatar?: Types.Maybe<string>;
    account: {
        __typename?: 'Account';
        id: string;
        name: string;
        permittedRiskInventories: Array<Types.Maybe<{ __typename?: 'AccountRiskInventory'; name: string }>>;
    };
    preferences: { __typename?: 'UserPreferences'; theme?: Types.Maybe<Types.IUiTheme> };
};

export const UserFragmentDoc = gql`
    fragment User on User {
        id
        name
        avatar
        account {
            id
            name
            permittedRiskInventories {
                name
            }
        }
        preferences {
            theme
        }
    }
`;
