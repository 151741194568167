import { datadogRum } from '@datadog/browser-rum';

export function datadogInit() {
    if (
        process?.env?.REACT_APP_DATADOG_APPLICATION_ID &&
        process?.env?.REACT_APP_DATADOG_CLIENT_TOKEN &&
        process?.env?.REACT_APP_BUILD_ENV &&
        // initialise datadog only for 'int' and 'prod'
        ['int', 'prod'].includes(process?.env?.REACT_APP_BUILD_ENV)
    ) {
        datadogRum.init({
            applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
            clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
            site: 'datadoghq.eu',
            service: 'acin-terminal',
            env: process.env.REACT_APP_BUILD_ENV,
            version: '1.0.0',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
        });
    }
}
