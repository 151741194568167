import React from 'react';
import { DownloadsList as DownloadsListComp, useToaster } from '@acin/ui-core';
import { DownloadItem } from '@acin/ui-core/types/src/components/DownloadsList/components/types';

import * as SC from './DownloadsList.styled';

export interface DownloadsListProps {
    onClose: () => void;
    deleteCalibrateDownload: (id: string) => void;
    allCalibrateDownloads: DownloadItem[] | undefined;
    markCalibrateAsDownloaded: (id: string) => void;
}

export const DownloadsList: React.FC<DownloadsListProps> = (props) => {
    const { onClose, allCalibrateDownloads, deleteCalibrateDownload, markCalibrateAsDownloaded } = props;
    const { onAddToast } = useToaster();

    const handleDownloadClick = (item: DownloadItem) => {
        if (item.id && item.fileURL) {
            const openDownload = () => {
                return item.fileURL && window.open(item.fileURL, '_blank');
            };
            const newWindow = openDownload();

            if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
                // If the popup is blocked, show a download button
                onAddToast({
                    id: `${item.id}-complete`,
                    title: 'Download Blocked',
                    message:
                        'Pop-ups have been disabled for this browser. Please enable pop-ups for this site to allow future downloads.',
                    status: 'warning',
                    actionPrimary: {
                        label: 'Download Now',
                        onClick: openDownload,
                    },
                });
            } else {
                // If we can auto download then just say it is complete:
                onAddToast({
                    id: `${item.id}-complete`,
                    title: 'Download Complete',
                    hideCloseButton: false,
                    status: 'success',
                });
            }
            markCalibrateAsDownloaded(item.id);
        }
    };

    const handleDelete = (item: DownloadItem) => {
        const id = item.id;
        if (id) {
            deleteCalibrateDownload(id);
        }
    };

    return (
        <SC.DownloadsWrapper>
            <DownloadsListComp
                items={allCalibrateDownloads}
                onClick={handleDownloadClick}
                onDelete={handleDelete}
                onClose={onClose}
            />
        </SC.DownloadsWrapper>
    );
};
