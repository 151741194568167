import React from 'react';
import { Heading, Text } from '@acin/ui-core';

interface Error {
    error: string;
    desc: string;
}

export const Auth = () => {
    const [error, setError] = React.useState<Error | null>(null);

    React.useEffect(() => {
        if (window.location.search.includes('error=')) {
            const params = new URLSearchParams(window.location.search);
            const error = params.get('error');
            const desc = params.get('error_description');

            if (error && desc) {
                setError({ error, desc });
            }
        }
    }, []);

    if (error) {
        return (
            <div>
                <Heading>{error.error}</Heading>
                <Text>{error.desc}</Text>
            </div>
        );
    }

    return <div />;
};
