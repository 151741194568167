import { Theme } from '@acin/ui-core';

export const getDotColor = (activeTheme: Theme, oneFailed: boolean, oneInProgress: boolean, oneProcessed: boolean) => {
    if (oneFailed) {
        return activeTheme.colors.error.getValue(500).main;
    } else if (oneProcessed) {
        return activeTheme.colors.success.getValue(500).main;
    } else if (oneInProgress) {
        return activeTheme.colors.info.getValue(500).main;
    }
    return undefined;
};
