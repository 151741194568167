import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDownload } from '@acin/download';
import { useAuth, USER_ROLES } from '@acin/user-accounts';
import { UserAccountModal } from '@acin/terminal-onboarding';
import {
    MainNavHorizontal as CoreMainNav,
    CommonProp,
    useThemeProvider,
    themeHelpers,
    IconUpload,
} from '@acin/ui-core';
import { SearchField, SearchDropdown, SearchProvider } from '@acin/search';
import * as SC from './MainNav.styled';
import { DownloadsList } from './DownloadsList';
import { UserPreferencesModal } from '../../UserPreferencesModal';
import { getMenuItems, getPathById, reverseRoutingMap } from '../config/horizontalMenu';

import { useMeQuery } from '../graphql/queries/me.generated';
import { onNavigate } from '../../../pages/Search/helpers/onNavigate';
import { IUiTheme } from '../../../graphql/generated';
import { getDotColor } from './utils';
import { DownloadIconAnimated } from './DownloadsList.styled';
import { useGetReportCategoriesQuery } from '../graphql/queries/getReportCategories.generated';

// TODO: Make this exportable from UI Core
type MenuItem = {
    label: string;
    id: string;
};

export interface MainNavProps extends CommonProp {
    className?: string;
    showItems?: boolean;
}

export const MainNav: React.FC<MainNavProps> = () => {
    const { logout, user, hasRole } = useAuth();
    const history = useHistory();
    const location = useLocation();
    const { data } = useMeQuery();

    const { isDarkMode } = themeHelpers;

    const { setTheme, activeTheme } = useThemeProvider();

    const { data: reportCategories } = useGetReportCategoriesQuery({
        fetchPolicy: 'cache-first',
    });

    const {
        allCalibrateDownloads,
        markCalibrateAsDownloaded,
        oneCalibrateDownloadInProgress,
        oneCalibrateDownloadProcessed,
        oneCalibrateDownloadFailed,
        deleteCalibrateDownload,
        showCalibrateDownloads,
        setShowCalibrateDownloads,
    } = useDownload();

    const closeDownloads = () => {
        setShowCalibrateDownloads(false);
    };

    const [showUserAccount, setShowUserAccount] = React.useState(false);
    const [showPreferencesModal, setShowPreferencesModal] = React.useState(false);
    const [stopDotAnimation, setStopDotAnimation] = React.useState(false);

    const [searchTerm, setSearchTerm] = React.useState<string | undefined>();
    const [showDropdown, setShowDropdown] = React.useState<boolean>();
    const [widenSearch, setWidenSearch] = React.useState<boolean>();
    const fieldRef = React.useRef<HTMLDivElement>(null);

    const [position, setPosition] = React.useState<{ top: number; left: number; width: number } | undefined>(undefined);

    React.useEffect(() => {
        if (widenSearch && fieldRef.current) {
            const rect = fieldRef.current.getBoundingClientRect();
            setPosition({ top: rect.bottom, left: rect.left, width: rect.width });
            handleOpenSearch();
        }
    }, [widenSearch]);

    React.useEffect(() => {
        if (showCalibrateDownloads && oneCalibrateDownloadInProgress) {
            setStopDotAnimation(false);
        }
    }, [oneCalibrateDownloadInProgress, showCalibrateDownloads]);

    React.useEffect(() => {
        if (data?.me.preferences?.theme === IUiTheme.Dark) {
            setTheme(activeTheme);
        }
    }, [data]);

    const getActiveMenuItem = React.useCallback(() => {
        let activeMenuItem = 'dashboard';
        reverseRoutingMap.forEach((value, key) => {
            if (location.pathname.includes(key)) {
                activeMenuItem = value;
            }
        });
        return activeMenuItem;
    }, [location.pathname]);

    const onClick = (id: string) => {
        const path = getPathById(id);
        if (path) {
            history.push(path);
        } else {
            history.push(id);
        }
    };

    const onLogoClick = () => {
        if (hasRole([USER_ROLES.RISK_INTEL_USER])) {
            history.push('/risk-intelligence/hub');
        }

        if (hasRole([USER_ROLES.SUPER_ADMIN, USER_ROLES.ACIN_USER, USER_ROLES.DATA_USER, USER_ROLES.CUSTOMER_USER])) {
            history.push('/');
        }
    };

    const buildReportCategories = reportCategories?.getAllReportsQuickFilters.filters.map((category) => ({
        label: category.filter?.label!,
        id: category.filter?.value!,
    }));

    let menuItems =
        user?.accountCode && user?.totalInventories
            ? getMenuItems(user.accountCode, user?.roles, buildReportCategories)
            : [];

    const dotColor = getDotColor(
        activeTheme,
        oneCalibrateDownloadFailed,
        oneCalibrateDownloadInProgress,
        oneCalibrateDownloadProcessed,
    );

    const userMenuItems = [
        { id: 'preferences', label: 'Preferences' },
        { id: 'logout', label: 'Logout' },
    ];

    if (data?.me?.name) {
        userMenuItems.unshift({ id: 'myaccount', label: 'My Account' });
    }

    const handleMenuClick = (id?: string) => {
        switch (id) {
            case 'logout':
                localStorage.clear();
                let redirect: any;
                if (process.env.NODE_ENV !== 'development') {
                    redirect = {
                        returnTo: `https://${window.location.hostname}`,
                    };
                }
                if (process.env.REACT_APP_CYPRESS_ENABLE === 'true') {
                    redirect = {
                        returnTo: `http://${window.location.hostname}:3000`,
                    };
                }
                logout(redirect);
                break;
            case 'myaccount':
                setShowUserAccount(true);
                break;
            case 'preferences':
                setShowPreferencesModal(true);
                break;
            case 'recent-downloads':
                setStopDotAnimation(true);
                setShowCalibrateDownloads(!showCalibrateDownloads);
                break;
            default:
                setShowCalibrateDownloads(false);
        }
    };

    const getIcons = () => {
        const navIcons = [
            {
                id: 'recent-downloads',
                label: 'Recent Downloads',
                icon: () => (
                    <DownloadIconAnimated
                        dotColor={dotColor}
                        stopAnimation={stopDotAnimation}
                        color={
                            isDarkMode(activeTheme)
                                ? activeTheme?.colors.text.getValue(0)
                                : activeTheme?.colors.text.getValue(3)
                        }
                    />
                ),
                onClick: () => setShowCalibrateDownloads(!showCalibrateDownloads),
            },
        ];
        // temporary: add upload icon only for SUPER_ADMIN user:
        if (hasRole([USER_ROLES.SUPER_ADMIN])) {
            return [
                ...navIcons,
                {
                    id: 'upload',
                    label: 'Uploads',
                    icon: () => (
                        <IconUpload
                            color={
                                isDarkMode(activeTheme)
                                    ? activeTheme?.colors.text.getValue(0)
                                    : activeTheme?.colors.text.getValue(3)
                            }
                        />
                    ),
                    onClick: () => history.push('/upload'),
                },
            ];
        }
    };

    const showSearch = hasRole([
        USER_ROLES.SUPER_ADMIN,
        USER_ROLES.ACIN_USER,
        USER_ROLES.DATA_USER,
        USER_ROLES.CUSTOMER_USER,
        USER_ROLES.RISK_INTEL_USER,
    ]);

    const handleCloseSearch = () => {
        setShowDropdown(false);
        setSearchTerm('');
        setWidenSearch(false);
    };

    const handleOpenSearch = () => {
        setWidenSearch(true);
        setTimeout(() => {
            setShowDropdown(true);
        }, 100);
    };

    return (
        <SearchProvider>
            <CoreMainNav
                navigation={menuItems}
                onNavigationClick={(item: MenuItem) => {
                    setShowCalibrateDownloads(false);
                    onClick(item.id);
                }}
                onLogoClick={onLogoClick}
                activeItem={getActiveMenuItem()}
                renderSearch={() =>
                    showSearch && (
                        <SC.SearchBarWrapper widenSearch={widenSearch}>
                            <SC.SearchBarExtender ref={fieldRef}>
                                <SearchField
                                    onClick={handleOpenSearch}
                                    searchTerm={searchTerm}
                                    onSearch={setSearchTerm}
                                    onClear={() => setSearchTerm(undefined)}
                                />
                            </SC.SearchBarExtender>
                        </SC.SearchBarWrapper>
                    )
                }
                settingsMenuContents={userMenuItems}
                onMenuClick={(item) => handleMenuClick(item?.id)}
                icons={getIcons()}
            />
            <SC.DropdownHolder>
                <SearchDropdown
                    onNavigate={onNavigate}
                    onClose={handleCloseSearch}
                    isVisible={showDropdown}
                    searchTerm={searchTerm}
                    position={position}
                />
            </SC.DropdownHolder>
            <UserPreferencesModal show={showPreferencesModal} onClose={() => setShowPreferencesModal(false)} />
            {showUserAccount && (
                <UserAccountModal
                    showInventories={hasRole([USER_ROLES.SUPER_ADMIN, USER_ROLES.ACIN_USER, USER_ROLES.DATA_USER])}
                    show={showUserAccount}
                    onClose={() => setShowUserAccount(false)}
                />
            )}
            {showCalibrateDownloads && (
                <DownloadsList
                    onClose={closeDownloads}
                    deleteCalibrateDownload={deleteCalibrateDownload}
                    allCalibrateDownloads={allCalibrateDownloads}
                    markCalibrateAsDownloaded={markCalibrateAsDownloaded}
                />
            )}
        </SearchProvider>
    );
};
