import styled, { css } from 'styled-components';

export const SearchBarWrapper = styled.div<{ widenSearch?: boolean }>`
    position: relative;
    z-index: 2;
    margin-left: 0;
    max-width: ${(p) => p.theme.spacing.getSize(47)};
    height: ${(p) => p.theme.spacing.getSize(15)};
    min-width: ${(p) => p.theme.spacing.getSize(47)};

    ${(p) =>
        p.widenSearch &&
        css`
            margin-left: -660px;
            max-width: 1000px;
        `}
`;

export const SearchBarExtender = styled.div`
    position: absolute;
    right: 0;
    top: 5px;
    width: 100%;
    min-width: ${(p) => p.theme.spacing.getSize(47)};
    transition: '1s ease-in-out all';
`;

export const DropdownHolder = styled.div`
    position: relative;
    z-index: 3;
`;
