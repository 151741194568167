/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Layout, PrivateRoute as UiCorePrivateRoute } from '@acin/ui-core';

import { NoAccessPage } from '@acin/terminal-onboarding';
import { useAuth } from '@acin/user-accounts';
import { MainNav } from '../components/MainNav';

export const PrivateRoute: React.FC<RouteProps> = (props) => {
    const { isAuthenticated, loading, user } = useAuth();
    const { children, ...rest } = props;

    if (loading) {
        return null;
    }

    const renderContent = () => {
        if (!user?.accountCode) {
            return (
                <Route
                    {...rest}
                    render={() => {
                        return (
                            <Layout
                                renderNavigation={() => <MainNav />}
                                renderContent={() => <NoAccessPage userName="" hasAccount={false} />}
                            />
                        );
                    }}
                />
            );
        }

        if (user?.totalInventories === 0) {
            return (
                <Route
                    {...rest}
                    render={() => {
                        return (
                            <Layout
                                renderNavigation={() => <MainNav />}
                                renderContent={() => (
                                    <NoAccessPage
                                        accountName={user?.accountCode}
                                        userName={user?.name}
                                        hasAccount={true}
                                    />
                                )}
                            />
                        );
                    }}
                />
            );
        }

        return children;
    };

    return (
        <UiCorePrivateRoute isAuthenticated={isAuthenticated} redirectPath="/login">
            {renderContent()}
        </UiCorePrivateRoute>
    );
};
