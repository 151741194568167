import { UserMetadata, AuthUser } from '@acin/user-accounts';
import { analytics } from '../analytics';

declare global {
    interface Window {
        pendo: any;
    }
}

/**
 * Pendo Initialization (Additional Docs here: https://developers.pendo.io/docs/?bash#functions)
 * @param user - (required) AuthUser
 * @param userMetadata - (required) UserMetadata
 */
export const initialisePendo = (user: AuthUser, userMetadata: UserMetadata) => {
    window.pendo = window.pendo || {};
    window.pendo.initialize &&
        window.pendo.initialize({
            visitor: {
                id: user.sub,
                email: user.email,
            },
            account: {
                id: userMetadata?.account?.code,
            },
            annotateUrl: () => {
                return analytics.getAnalyticsUrl();
            },
        });
};
